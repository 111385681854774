import { createRouter, createWebHistory } from 'vue-router'
import HablaExperto from './components/HablaExperto.vue'
import CotizadorWor from './components/CotizadorWor.vue'

const routes = [
    {
        path: '/habla',
        name: 'HablaExperto',
        component: HablaExperto
    },
    {
        path: '/cotizador',
        name: 'CotizadorWor',
        component: CotizadorWor
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
