<template>
    <form @submit.prevent="submitForm">
        <div class="container-fluid forms">
            <div class="row">
                <div class="col-12 col-md-6 col-sm-6">
                    <div class="form-floating">
                        <input type="text" class="form-control" v-model="formData.name" id="exp_name"
                            placeholder="Ingresa tu nombre" required @blur="validateName">
                        <label for="exp_name">Nombre <span class="obligatorio">(*)</span></label>
                        <p class="invalid-feedback d-flex" v-if="errors.name">{{ errors.name }}</p>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-sm-6">
                    <div class="form-floating">
                        <input type="text" class="form-control" v-model="formData.company" @blur="validateCompany"
                            id="exp_company" placeholder="Ingresa tu empresa" required>
                        <label for="exp_company">Empresa <span class="obligatorio">(*)</span></label>
                        <p class="invalid-feedback d-flex" v-if="errors.company">{{ errors.company }}</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-6 col-sm-6">
                    <div class="form-floating">
                        <input type="email" class="form-control" v-model="formData.email" @blur="validateEmail"
                            id="exp_email" placeholder="Ingresa tu correo electrónico" required>
                        <label for="exp_email">Correo electrónico <span class="obligatorio">(*)</span></label>
                        <p class="invalid-feedback d-flex" v-if="errors.email">{{ errors.email }}</p>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-sm-6">
                    <div class="form-floating">
                        <input type="tel" class="form-control" v-model="formData.phone" id="exp_phone"
                            placeholder="Ingresa tu teléfono">
                        <label for="exp_phone">Teléfono</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="form-floating">
                        <textarea class="form-control" placeholder="Deja un comentario aquí" v-model="formData.message"
                            id="exp_message"></textarea>
                        <label for="exp_message">¿Cuál es tu duda? déjanos tu comentario</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <p>Al hacer clic en “Enviar” aceptas las <a href="https://checkid.cl/privacidad/"
                            target="_blank">condiciones de privacidad.</a></p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <button type="submit" :disabled="isFormInvalid" class="btn btn-primary">Enviar</button>
                </div>
            </div>
            <div class="row">
                <div class="col-12 mt-3">
                    <p class="messageFinal">{{ messagefinal }}</p>
                </div>
            </div>
        </div>
    </form>
    <div class="loader" v-if="isLoading">
        <div class="spinner-border m-5" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            isLoading: true,
            messagefinal: '',
            formData: {
                name: '',
                company: '',
                phone: '',
                email: '',
                message: ''
            },
            errors: {},
        }
    },
    computed: {
        isFormInvalid() {
            return this.formData.name === '' || this.formData.email === '' || Object.keys(this.errors).length > 0;
        }
    },
    mounted() {
        this.isLoading = false
    },
    methods: {
        validateName() {
            if (this.formData.name === '') {
                this.errors.name = 'El nombre es obligatorio';
            } else {
                delete this.errors.name;
            }
        },
        validateCompany() {
            if (this.formData.company === '') {
                this.errors.company = '¿A qué empresa representas?';
            } else {
                delete this.errors.company;
            }
        },
        validateEmail() {
            const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
            if (!emailRegex.test(this.formData.email)) {
                this.errors.email = 'El correo electrónico no es válido';
            } else {
                delete this.errors.email;
            }
        },
        async submitForm() {
            try {
                this.isLoading = true
                // create organization
                let dataOrg = JSON.stringify({
                    "name": this.formData.company,
                    //ID VPO
                    "owner_id": "16689989"
                });
                //config organization
                let config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: 'https://api.pipedrive.com/v1/organizations?api_token=5b7ded45f6a3bf88107432697b8579eacf15bfcd',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    data: dataOrg
                };
                //call organization
                axios.request(config)
                    .then((response) => {
                        // console.log(JSON.stringify(response.data));
                        let IdCompany = response.data.data.id;
                        // data Person
                        let dataPerson = JSON.stringify({
                            "name": this.formData.name,
                            "owner_id": 16689989,
                            "org_id": IdCompany,
                            "email": [
                                {
                                    "value": this.formData.email,
                                    "primary": true,
                                    "label": "work"
                                }
                            ],
                            "phone": [
                                {
                                    "value": this.formData.phone,
                                    "primary": true,
                                    "label": "mobile"
                                }
                            ]
                        });

                        // Create person
                        let config = {
                            method: 'post',
                            maxBodyLength: Infinity,
                            url: 'https://api.pipedrive.com/v1/persons?api_token=5b7ded45f6a3bf88107432697b8579eacf15bfcd',
                            headers: {
                                'Content-Type': 'application/json',
                                'Accept': 'application/json'
                            },
                            data: dataPerson
                        };
                        // call person
                        axios.request(config)
                            .then((response) => {
                                // console.log(JSON.stringify(response.data));
                                let IdPerson = response.data.data.id;
                                // data leads
                                let data = JSON.stringify({
                                    "title": this.formData.company,
                                    "owner_id": 16689989,
                                    "person_id": IdPerson,
                                    "organization_id": IdCompany,
                                    "f37ea35723dc1496f4c10b777484cf705793425c": this.formData.message,
                                    "label_ids": [
                                    ],
                                    "6570ac49049cd926d5bcdfa53edbb18289b2418a": 26
                                });
                                //config leads
                                let config = {
                                    method: 'post',
                                    maxBodyLength: Infinity,
                                    url: 'https://api.pipedrive.com/v1/leads?api_token=5b7ded45f6a3bf88107432697b8579eacf15bfcd',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Accept': 'application/json',
                                    },
                                    data: data
                                };
                                // call leads
                                axios.request(config)
                                    .then((response) => {
                                        console.log(JSON.stringify(response.data));
                                        this.messagefinal = 'Hemos recibido tus datos correctamente, nos contactaremos contigo a la brevedad.'
                                        this.formData.name = '';
                                        this.formData.email = '';
                                        this.formData.company = '';
                                        this.formData.phone = '';
                                        this.formData.message = '';
                                        this.isLoading = false
                                    })
                                    .catch((error) => {
                                        console.error('Error al enviar los datos:', error);
                                        this.messagefinal = 'Ha ocurrido un problema, no fue posible recibir tus datos.'
                                        this.isLoading = false
                                    });

                            })
                            .catch((error) => {
                                console.error('Error al enviar los datos:', error);
                                this.messagefinal = 'Ha ocurrido un problema, no fue posible recibir tus datos.'
                                this.isLoading = false
                            });
                    })
                    .catch((error) => {
                        console.error('Error al enviar los datos:', error);
                        this.messagefinal = 'Ha ocurrido un problema, no fue posible recibir tus datos.'
                        this.isLoading = false
                    });

            } catch (error) {
                this.isLoading = false
                console.error('Error al enviar los datos:', error);
                this.messagefinal = 'Ha ocurrido un problema, no fue posible recibir tus datos.'
                this.isLoading = false
            }
        },
    }
}
</script>

<style scoped>
.loader {
    background-color: rgba(20, 128, 204, 0.2);
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    z-index: 2;
    width: 100%;
}

.forms {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.forms .form-floating {
    margin-bottom: 1rem;
}

.forms label {
    color: #37474f;
}

.forms label .obligatorio {
    font-size: 11px;
    color: red;
    font-weight: bold;
    margin-top: -4px;
    display: inline-block;
}

.forms p.messageFinal {
    color: #1481D0;
    font-size: 16px;
}

.forms p a {
    color: #1481D0;
}

.forms .btn {
    background-color: #1481D0;
    border-color: #1481D0;
    min-width: 150px;
}

.forms .btn:hover {
    background-color: #37474f;
    border-color: #37474f;
}
</style>