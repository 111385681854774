<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
  components: {}
}
</script>
<style>
body {
  background: rgba(255, 255, 255, 0.00) !important;
}
</style>